import React, { useState } from 'react';
import { Profile as ProfileType } from '../../../../types';
import { SubContainer } from '../../Core/Layout';
import { Box, Flex, Grid, GridItem, Heading, Text } from '@chakra-ui/react';
import { KeyAndValue } from './KeyAndValue';
import { useDispatch, useSelector } from 'react-redux';
import { updatePhoneNumber } from './EditRequests';
import { request } from '../../../../helper/useAxios';
import { appConstants } from '../../../../helper/client/constant';
import { setProfile } from '../../../../reducers';
import { LocationSection } from './LocationSection';
import { Option } from '../../Core/FormElements/FormElementType';
import { Tooltip } from '../../Core/Tooltip/Tooltip';

export interface ProfileProps {
  personalDetailsTitle: string;
  personalDetailsDescription: string;
  locationDetailsTitle: string;
  locationDetailsDescription: string;
  profile: ProfileType;
  locationTooltip: string;
  editConfigs: { [key: string]: { heading: string; subHeading: string } };
  baseRefData: Option[];
  workingLocationRefData: Option[];
}

export const Profile: React.FC<ProfileProps> = ({
  profile,
  editConfigs,
  locationTooltip,
  baseRefData,
  workingLocationRefData,
  personalDetailsTitle,
  personalDetailsDescription,
  locationDetailsTitle,
  locationDetailsDescription,
}) => {
  const dispatch = useDispatch();
  const isInternal = useSelector((state: any) => state.isInternal);
  const [openLocation, setOpenLocation] = useState('');

  const updateProfile = () =>
    request(appConstants.API_USER_PROFILE_DETAILS, 'get').then((res) => dispatch(setProfile(res.data)));

  const MyDetails = () => {
    return (
      <>
        <Box as={GridItem} colSpan={{ lg: 3, base: 1 }} mb="1rem">
          <Heading as="h3" fontSize="sm" fontWeight="medium">
            {personalDetailsTitle}
          </Heading>
          <Text>{personalDetailsDescription}</Text>
        </Box>
        <KeyAndValue _key="givenNames" title="Given name(s)" value={profile?.givenNames} />
        <KeyAndValue _key="surname" title="Surname" value={profile?.surname} />
        {profile?.myGovIDLink?.myGovIDIdentity && (
          <KeyAndValue _key="didUsername" title="Digital ID username" value={profile?.myGovIDLink?.myGovIDIdentity} />
        )}
        <KeyAndValue
          _key="defenceEmail"
          title="Defence email address"
          value={isInternal ? profile?.userID : profile.myGovIDLink.defenceIdentity}
          myGovIDDetails={profile.myGovIDLink}
          idp={profile.idp}
          updateProfile={updateProfile}
          tooltipContent={
            <>
              Contact 1800DEFENCE (1800 333 362) to update an incorrect or previously verified Defence email address.
              <br />
              <strong>Note:</strong> Email verification is required every 90 days. An email with instructions will be
              sent to the provided Defence address. Please allow time for verification.
            </>
          }
        />
        <KeyAndValue
          _key="phone"
          title="Work phone (Skype or landline)"
          value={profile?.phone.find((item) => item.type === 'Home' || item.type === 'Work')?.number ?? ''}
          editable
          onUpdate={(value) => updatePhoneNumber(profile.phone, 'phone', value).then(updateProfile)}
        />
        <KeyAndValue
          _key="mobile"
          title="Mobile phone (work or personal)"
          value={profile?.phone.find((item) => item.type === 'Mobile')?.number ?? ''}
          editable
          onUpdate={(value) => updatePhoneNumber(profile.phone, 'mobile', value).then(updateProfile)}
        />
        <KeyAndValue
          _key="odsPmKeyS"
          title="ODS or PMKeyS"
          value={profile.employeeId === '' ? profile?.pmKeyS : profile?.employeeId}
        />
        <KeyAndValue _key="service" title="Service" value={profile?.service.short} />
        <KeyAndValue _key="group" title="Group" value={profile?.group.short} />
        <KeyAndValue _key="rankOrTitle" title="Rank or title" value={profile?.rank.short} />
      </>
    );
  };

  const LocationDetails = () => {
    return (
      <>
        <Box as={GridItem} colSpan={{ lg: 3, base: 1 }} mt="2rem" mb="1rem">
          <Heading as="h3" fontSize="sm" fontWeight="medium">
            {locationDetailsTitle}
          </Heading>
          <Text dangerouslySetInnerHTML={{ __html: locationDetailsDescription }} mb="1rem" />
          <Text gap={1} alignItems="center" color="orange.standard" fill="orange.standard" display="flex">
            Can’t find your location?
            <Tooltip
              content="If your base does not appear as an option in the list provided, please select the base closest to the intended location. The list of bases are obtained from the Garrison and Estate Management System (GEMS) and is limited to functional location and property level data. Building level data is currently not avaliable."
              placement="top"
            />
          </Text>
        </Box>
        <LocationSection
          _key="baseName"
          heading="Home Base"
          state={profile?.location.state}
          name={profile?.location.name}
          fullAddress={profile?.location.fullAddress}
          editMode={openLocation === 'home'}
          edit={() => setOpenLocation('home')}
          editConfig={{ ...editConfigs.home, tooltip: locationTooltip }}
          dropdownItems={baseRefData}
          cancel={() => setOpenLocation('')}
          update={updateProfile}
        />
        <LocationSection
          _key="alternateBaseName"
          heading="Alternative Base"
          state={profile?.alternateBaseLocation.state}
          name={profile?.alternateBaseLocation.name}
          fullAddress={profile?.alternateBaseLocation.fullAddress}
          editMode={openLocation === 'alt'}
          edit={() => setOpenLocation('alt')}
          editConfig={{ ...editConfigs.alt, tooltip: locationTooltip }}
          dropdownItems={baseRefData}
          cancel={() => setOpenLocation('')}
          update={updateProfile}
        />
        <LocationSection
          _key="workingLocation"
          heading="Working Location"
          state={profile?.workingLocation.state}
          name={profile?.workingLocation.name}
          fullAddress={profile?.workingLocation.fullAddress}
          editMode={openLocation === 'working'}
          edit={() => setOpenLocation('working')}
          editConfig={{ ...editConfigs.working, tooltip: locationTooltip }}
          dropdownItems={workingLocationRefData}
          cancel={() => setOpenLocation('')}
          update={updateProfile}
        />
      </>
    );
  };

  return (
    <SubContainer verticalMargin="1rem">
      <Grid gap={3} gridTemplateColumns={{ lg: 'repeat(3,1fr)', base: 'repeat(1,1fr)' }}>
        <MyDetails />
        <LocationDetails />
      </Grid>
    </SubContainer>
  );
};
