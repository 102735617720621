import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Box,
  Text,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Flex,
  Button,
} from '@chakra-ui/react';
import InfoBox from '../../Core/InfoBox/InfoBox';
import { Link } from '../../Core/icons/Outline';

interface VerifyEmailProps {
  isOpen: boolean;
  close(): void;
  verifyEmail(e): void;
}
interface VerifyEmailCompleteProps {
  isOpen: boolean;
  close(): void;
  edit(): void;
}

export const VerifyEmail: React.FC<VerifyEmailProps> = ({ isOpen, close, verifyEmail }) => {
  const [isValid, setIsValid] = useState(true);
  const [value, setValue] = useState('');
  const devEnvs = ['local', 'dev', 'test2'];
  const validEmailDomains = [
    'defence.gov.au',
    'dpe.protected.mil.au',
    'drn.mil.au',
    'navy.gov.au',
    'deployed.mil.au',
    'ddep.mil.au',
  ];
  const validEmailDomainsForDEV = ['accenture.com', ...validEmailDomains];
  useEffect(() => {
    const inputEmailDomain = value?.split('@')[1];
    value !== '' &&
      setIsValid(
        (devEnvs.includes(process.env.REACT_APP_CURRENT ?? '') ? validEmailDomainsForDEV : validEmailDomains).includes(
          inputEmailDomain,
        ),
      );
  }, [value]);

  return (
    <Modal size="xl" isOpen={isOpen} onClose={() => {}}>
      <ModalOverlay />
      <ModalContent p="1rem">
        <InfoBox desktopPadding="1rem">
          <Link height="3rem" />
          <Box className="p-2"></Box>
          <Text fontWeight="medium" mb="0.5rem">
            Verify your Defence email address
          </Text>
          <Text fontWeight="light" fontSize="xs">
            Some services and features in ServiceConnect are only available to personnel with a valid Defence email
            address. If you are using ServiceConnect outside the Defence Protected Environment (DPE) and have not yet
            verified your Defence email address, provide your email address below to start the verification process.
          </Text>
          <FormControl isRequired isInvalid={!isValid}>
            <FormLabel>Defence email address (e.g., john.smith@defence.gov.au)</FormLabel>
            <Input variant="primary" value={value} onChange={(e) => setValue(e.target.value)} />
            <FormErrorMessage>Please enter a valid defence email account.</FormErrorMessage>
          </FormControl>
        </InfoBox>
        <Flex mt="1rem" justifyContent="space-between" gap={3} flexDir={{ lg: 'row', base: 'column' }}>
          <Button variant="tertiary" onClick={close}>
            Close
          </Button>
          <Button isDisabled={!isValid || value === ''} variant="primary" onClick={() => verifyEmail(value)}>
            Submit
          </Button>
        </Flex>
      </ModalContent>
    </Modal>
  );
};
